import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><em parentName="p">{`Test Week`}</em></p>
    <p>{`Max Muscle Ups, 2 attempts`}</p>
    <p>{`Hang Squat Snatch 5×1 to a 1RM`}</p>
    <p>{`then,`}</p>
    <p>{`10:00 AMRAP of:`}</p>
    <p>{`2-Bar Muscle Ups`}</p>
    <p>{`2-Power Snatch (115/75)`}</p>
    <p>{`4-Bar Muscle Ups`}</p>
    <p>{`4-Power Snatch`}</p>
    <p>{`6-Bar Muscle Ups`}</p>
    <p>{`6-Power Snatch`}</p>
    <p>{`etc.`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`Our next Mobility class will from from 11:00-11:30 Saturday, March
16th.`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`Our new class schedule will start next Monday, March 17th along
with our new Ville Bootcamp Tues-Friday at 9:30am!  Evening CrossFit
classes will now be offered Monday-Friday at 4:00, 5:00 & 6:00pm!`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      